<script>
import ChartComp from "@/components/ChartComp.vue"
import * as getReports from "../api/reports/get";
import { mapGetters } from 'vuex';
const randomFlatColors = require("random-flat-colors");

export default {
    components: {ChartComp},
    props: {
        filters: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            labels: [
			],
			datasets: [{
				label: 'Pilares de cultura',
				data: [],
				backgroundColor: [],
				hoverOffset: 4,
			}],
            options: {
                plugins: {
                    legend: {
                        position: 'left',
                        labels: {
                            font: {
                                size: 18,
                                
                            },
                            color: '#4a6c85'
                        },
                    }
                }
            },
            result: null,
            count: 0
        }
    },
    computed: {
        ...mapGetters({
            token: "loginStore/readToken",
        })
    },
    watch: {
        filters: {
            handler(newVal, oldVal) {
                this.loadReport()
            },
            deep: true
        }
    },
    methods: {
        loadReport(page=1) {
			let token = this.token

			let params =  {
				page: page,
				size: 15,
				// sort_by: this.sort.by,
				// sort: this.sort.sort
			}

			for (const property in this.filters) {
				params[property] = this.filters[property].selected
			}


			getReports.getVotesByCultures(token, params)
			.then(result => {
				this.$set(this, 'result', result)
                this.count = result.items.length
                this.setChartData()
			})
			.finally(() => {
				// this.listed = true;
				// this.setLoading(false);
			});
			
		},

        setColors(arr) {
            let color = randomFlatColors()
            if(arr.indexOf(color) >= 0) {
                this.setColors(arr)
            } else {
                arr.push(color)
            }
        },

        setChartData() {
            let labels = this.result.items.map(i => `${i.received_votes}  ${i.name}`)
            let data = this.result.items.map(i => i.received_votes)
            let backgroundColor = ['#17AD12', '#01B572', '#F73434', '#FE5A5A', '#EA660A', '#EF8806', '#FFB800']


            // this.result.items.forEach(element => {
            //     this.setColors(backgroundColor)
            // });

            this.$set(this, 'labels', labels)
            this.$set(this.datasets[0], 'data', data)
            this.$set(this.datasets[0], 'backgroundColor', backgroundColor)
            this.$nextTick(() => {
                this.$refs.chart?.prepare()
            })
        },
        moveLabels(x) {
            if (x.matches) { // If media query matches
                // document.body.style.backgroundColor = "yellow";
                this.options.plugins.legend.position = 'bottom'
                this.options.plugins.legend.labels.font.size = 14
                this.$refs.chart?.prepare()
            } else {
                this.options.plugins.legend.position = 'left'
                this.options.plugins.legend.labels.font.size = 18
                this.$refs.chart?.prepare()
            }
        }
    },

    mounted() {
        this.loadReport()

        var x = window.matchMedia("(max-width: 680px)")
        this.moveLabels(x) // Call listener function at run time
        x.addListener(this.moveLabels) // Attach listener function on state changes
    }
}
</script>

<template>
    <div>
        <h1 class="w-full text-3xl  py-6">
            Así se viven nuestros <span class="b red">{{count}}</span> pilares de cultura
        </h1>
        <ChartComp ref="chart" type="doughnut" :datasets="datasets" :labels="labels" :options="options" />
    </div>
</template>
